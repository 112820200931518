import React, { useEffect } from 'react'
import { Form } from "react-bootstrap"
import $ from 'jquery'
function myplaceholder(props) {
  return <div>{props.html}</div>;
}

function createMarkup(props) {
  return {__html: (props.html)};
}

function MyComponent(props) {
  return <div dangerouslySetInnerHTML={createMarkup(props)} />;
}
const RadioboxField = ({fieldClass, name, type, value, required, placeholder, handlechange, checked,step}) => {

  useEffect(() => {
    $(".radio-another_viewing").click(function () {
      $('.radio-another_viewing input[name=another_viewing]').removeAttr('checked').removeClass("active");
      $(this).find('input[name=another_viewing]').attr('checked', 'checked').addClass("active");
    });
    $(".radio-make_offer").click(function () {
      $('.radio-make_offer input[name=make_offer]').removeAttr('checked').removeClass("active");
      $(this).find('input[name=make_offer]').attr('checked', 'checked').addClass("active");
    });
  });

  return (
    <>
    {type == "radiogroup" ?
      <Form.Group className="form-field-group" >
          <Form.Label>{placeholder}</Form.Label>
          <div className={"custom-radio-field custom-radio-" + `${name}`}>
              <label className={"custom-radio-label radio-" + `${name}`}>
                  <input type='radio' className={name} id={name+1} name={name} value="Yes" />
                  <span className="custom-radio-button"></span>
                  Yes
              </label>
              <label className={"custom-radio-label radio-" + `${name}`}>
                  <input type='radio' className={name} id={name+2} name={name} value="No" />
                  <span className="custom-radio-button"></span>
                  No
              </label>
              <label className={"custom-radio-label radio-" + `${name}`}>
                  <input type='radio' className={name} id={name+3} name={name} value="Not sure" />
                  <span className="custom-radio-button"></span>
                  Not sure
              </label>
          </div>
      </Form.Group>
    :
      <div className="form-check form-check-inline">
      <input type="radio" id={name+value} name={name} checked={checked} value={value} onChange={handlechange} className="form-check-input" required={required} />
      <label title="" for={name+value} class="form-check-label">{placeholder}</label>
      </div>
    }
    </>
  )
};

export default RadioboxField;